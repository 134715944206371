/* Custom */
:root {
    --bs-body-bg: #0f1a25 !important;
}


html,
body {
    color: #ffffff !important;
    font-family: 'Roboto', sans-serif !important;
    max-width: 100%;
    overflow-x: hidden;
    /*background-image: url('./assets/Png/GraphicBackgroundBlue.png');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: bottom left;*/
    /*background-color: rgba(15, 26, 37, 0.1) !important;*/
}

.bold-text {
    font-weight: 600;
}

a {
    color: #838A8E !important;
    font-weight: 600;
}

/*a:focus,
a:visited,
a:active,*/
a:hover{
    color: #ffffff !important;
    font-weight: 600;
}

h1, h2, h3, h4, h5, h6 {
    text-transform: uppercase;
    font-weight: 600 !important;
}

.navbar-toggler > .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'%3e%3cpath stroke='%23838A8E' fill='%23838A8E' d='M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z'/%3e%3c/svg%3e") !important;
}

.navbar-toggler.collapsed > .navbar-toggler-icon {
    background-image: url('data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27><path stroke=%27rgba%28131, 138, 142, 0.55%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/></svg>') !important;
}

.navbar button {
    box-shadow: none !important;
}


.navbar-toggler,
.navbar-toggler:hover {
    text-decoration: none;
    border: none !important;
}

.navbar-collapse.collapse.show {
    margin-left: -12px;
}

.navbar-collapse.collapse.show a {
    margin: 15px;
}

.navigation-mobile {
    width: 100px !important;
    display: flex !important;;
    flex-direction: row !important;
    position: fixed;
    right: 25px;
    top: 33px;
}

.navigation-mobile > .nav-link {
    flex: 1;
    width: 30px;
}

.nav-link {
    font-weight: 600 !important;
    text-transform: uppercase;
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.test-layer {
    width: calc(100vw);
    height: calc(100vh);
    background-color: #ffffff;
}

.teaser-animate {
    overflow: hidden;
    position: relative;
    min-height: calc(100vh);
    /*background-image: url('./assets/Png/GraphicBackgroundBlue.png');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: bottom left;*/
}

.teaser-animate,
.layer {
    width: calc(100vw);
    height: calc(100vh);
    overflow: hidden;
    margin: 0 auto;
}

.layer {
    background-position: bottom center;
    background-size: auto;
    background-repeat: no-repeat;
    width: 100%;
    /*height: calc(100vh);*/
    position: fixed;
    z-index: -1;
}

.teaser-image-one {
    background-image: url('./assets/Png/GraphicBackgroundBlue.png');
    z-index: -1000;
}

.teaser-image-two {
    background-image: url('./assets/Png/GraphicSky.png');
    z-index: -900;
}

.teaser-image-three {
    background-image: url('./assets/Png/GraphicTrees.png');
    background-position: bottom left;
    bottom: -70px;
    z-index: -600;
}

.teaser-image-four {
    background-image: url('./assets/Png/GraphicGround.png');
    background-position: bottom center;
    left: 0px;
    bottom: -80px;
    z-index: -400;
    background-size: cover;
}

.teaser-image-five {
    background-image: url('./assets/Png/GraphicCloudsLeft.png');
    background-position: left;
    left: -300px;
    z-index: -800;
}

.teaser-image-six {
    background-image: url('./assets/Png/GraphicCloudsRight.png');
    background-position: right;
    right: -250px;
    z-index: -700;
}

.teaser-image-seven {
    background-image: url('./assets/Png/GraphicStoneCircle.png');
    background-position: top left;
    margin: -350px 0px 0px -350px;
    z-index: -500;
}

.teaser-image-eight {
    /*background-image: url('./assets/Png/GraphicPlayer.png');
    background-position: left bottom;
    background-size: 500px;*/
    z-index: 1;
    bottom: -100px;
    left: -100px;
    position: absolute;
    max-width: 40%;
    margin-right: auto;
}

.teaser-image-nine {
    background-image: url('./assets/Png/GraphicRunbenderLogo.png');
    background-position: center center;
    top: -150px;
    z-index: -400;
    background-size: 800px;
    /*left: 450px;*/
}

.teaser-image-ten {
    background-position: left bottom;
    position: fixed;
    top: 350px;
    z-index: -300;
}

.teaser-image-ten > svg {
    z-index: -300;
    width: 270px !important;
    height: 600px !important;
}

.teaser-image-eleven {
    background-position: left bottom;
    /*position: fixed;*/
    top: 435px;
    z-index: 20;
    left: 40px;
    transform: rotate(5deg);
    height: 300px;
}

.teaser-image-eleven > svg {
    z-index: -300;
    width: 305px !important;
    height: 305px !important;
    position: absolute;
}

.teaser-image-eleven > svg:last-child {
    margin-left: -15px;
    margin-top: -25px;
}

.teaser-image-twelve {
    background-position: left bottom;
    /*position: fixed;*/
    top: 415px;
    z-index: 20;
    left: 15px;
    transform: rotate(5deg);
    height: 300px;
}

.teaser-image-twelve > svg {
    z-index: -300;
    width: 305px !important;
    height: 305px !important;
    position: absolute;
}

.teaser-image-twelve > svg:last-child {
    margin-left: 360px;
    margin-top: -4px;
}

#imprint > .row > div,
#terms-of-service > .row > div,
#privacy-policy > .row > div {
    margin-bottom: 20px;
}

#terms-of-service > .row > div > div > div,
#privacy-policy > .row > div > div > div {
    margin-bottom: 20px;
}

#privacy-policy > .row > div > div > div > div,
#privacy-policy > .row > div > div > div > h4 {
    margin-bottom: 20px;
}

#privacy-policy a {
    word-break: break-all;
}

#imprint > .row > div:nth-child(3) > div {
    margin-bottom: 40px;
}

#error > .row > div > div > div {
    margin-bottom: 20px;
}

.error-page,
.imprint-page,
.terms-of-service-page,
.privacy-policy-page {
    min-height: calc(100vh);
    background-image: url('./assets/Png/GraphicBackgroundBlue.png');
    background-position: bottom center;
    background-size: auto;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.imprint-page,
.terms-of-service-page,
.privacy-policy-page {
    background-size: 100% 100% !important;
}

#footer {
    min-height: 200px;
    background-image: url('./assets/Png/GraphicBackgroundBlue.png');
    background-position: bottom center;
    background-size: auto;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    overflow: hidden;
}

#footer a {
    text-decoration: none !important;
    text-transform: uppercase;
}

#footer > div {
    margin-top: 20px;
}

#footer > div > div > div {
    margin-bottom: 20px;
}

#footer > .container > .row:nth-child(2) {
    z-index: 1000000;
    position: relative;
}

#error,
#imprint,
#terms-of-service,
#privacy-policy {
    margin-top: 150px;
}

.error-border,
.imprint-border,
.privacy-border,
.tos-border {
    position: fixed;
    top: 0;
    z-index: 1000;
}

/*.button-wishlist {
    background-image: url('./assets/svg/teaserButton.svg');
    background-position: center center;
    background-repeat: no-repeat;
}*/

.button-container-main {
    max-width: 400px;
    position: relative;
    top: 44%;
    left: 33.5%;
    margin: auto;
}

.button-wishlist {
    z-index: 10000;
    position: absolute;
    top: 44%;
    width: 125px;
    height: 100px;
    /* left: auto; */
    right: 680px;
}

.button-trailer {
    z-index: 10000;
    position: absolute;
    top: 44%;
    width: 125px;
    height: 100px;
    /* left: auto; */
    right: 950px;
}

.button-betatest {
    z-index: 10000;
    position: absolute;
    top: 53%;
    width: 125px;
    height: 100px;
    /* left: auto; */
    right: 815px;
    margin-top: 100px;
}

.button-betatest {
    font-weight: bold !important;
}

.button-wishlist > a > img,
.button-betatest > a > img,
.button-betatest > img,
.button-trailer > img {
    width: 250px;
}

.button-wishlist:hover > a > img,
.button-betatest:hover > img,
.button-trailer:hover > img {
    fill: #53C2EC;
    transition: transform .5s ease-in-out;
    transform: scale(1.1, 1.1);
}

.button-wishlist span.german-text,
.button-betatest span.german-text,
.button-trailer span.german-text {
    left: 83px;
}

.button-trailer span.german-text {
    left: 78px !important;
}

.button-wishlist span.german-text-two,
.button-betatest span.german-text-two,
.button-trailer span.german-text-two {
    left: 42px;
}

.button-wishlist span.german-text-two {
    left: 62px;
    width: 200px !important;
}

.button-wishlist span.other-text,
.button-betatest span.other-text,
.button-trailer span.other-text {
    left: 70px;
    width: 250px;
}

.button-wishlist span.other-text {
    left: 85px !important;
    width: 250px;
}

.button-trailer span.other-text {
    left: 75px !important;
    width: 250px;
}

.button-wishlist span.spanish-text,
.button-betatest span.spanish-text,
.button-trailer span.spanish-text {
    left: 62.5px;
    width: 250px;
}

.button-wishlist span.rest-text-one,
.button-betatest span.rest-text-one,
.button-trailer span.rest-text-one {
    left: 26px;
    width: 250px;
}

.button-wishlist span.rest-text-two,
.button-betatest span.rest-text-two,
.button-trailer span.rest-text-two {
    left: 28px;
    width: 250px;
}

.button-wishlist span.rest-text-two {
    left: 53px !important;
    width: 250px;
}

.button-wishlist span.rest-text,
.button-betatest span.rest-text,
.button-trailer span.rest-text {
    left: 15px;
    width: 250px;
}

.button-wishlist span.rest-text {
    left: 65px;
    width: 250px;
}

.button-wishlist:hover span,
.button-betatest:hover span,
.button-trailer:hover span {
    color: #FFFFFF;
}

.button-wishlist span,
.button-betatest span,
.button-trailer span {
    text-transform: uppercase;
    text-decoration: none;
    position: absolute;
    top: 17px;
    left: 73px;
    font-size: 24px;
    color: #A7CFDE;
}
.button-betatest span {
    left: 93px !important;
}

.button-trailer span {
    font-weight: bold;
}

.corner-stone-rune-description {
    /*background-image: url('./assets/Png/GraphicStoneCircle.png');
    background-position: top left;*/
    margin: -360px 0px 0px -325px;
    position: absolute;
    transform: rotate(2deg);
    top: 0;
}

.corner-stone-rune-main-one {
    /*background-image: url('./assets/Png/GraphicStoneCircle.png');
    background-position: top left;*/
    margin: -360px 0px 0px -325px;
    position: fixed;
    transform: rotate(0deg);
    top: 0;
    z-index: 1;
}

.corner-stone-rune-error {
    /*background-image: url('./assets/Png/GraphicStoneCircle.png');
    background-position: top left;*/
    margin: -360px 0px 0px -325px;
    position: fixed;
    transform: rotate(245deg);
    top: 0;
}

.corner-stone-rune-imprint,
.corner-stone-rune-privacy,
.corner-stone-rune-tos {
    /*background-image: url('./assets/Png/GraphicStoneCircle.png');
    background-position: top left;*/
    margin: -360px 0px 0px -325px;
    position: fixed;
    transform: rotate(278deg);
    top: 0;
}

.corner-stone-rune-main {
    /*background-image: url('./assets/Png/GraphicStoneCircle.png');
    background-position: top left;*/
    margin: -360px 0px 0px -325px;
    position: absolute;
    transform: rotate(0deg);
    top: 0;
}

#footer > div > div:nth-child(1) > div:nth-child(2) {
    background-color: transparent;
    width: 300px;
    margin: 0px auto 0px auto;
    height: 206px;
    cursor: pointer;
}


#footer > div > div:nth-child(1) > div:nth-child(2) > img,
#footer > div > div:nth-child(1) > div:nth-child(2) > a > img {
    object-fit: cover;
    width: 250px;
    margin-top: 90px;
}

.teaser-main-mobile {
    min-height: calc(100vh);
}

.game-background {
    min-height: calc(100vh);
    background-image: url('./assets/Png/GraphicRunbenderLogo.png'), url('./assets/Png/GraphicCloudsRight.png'), url('./assets/Png/GraphicCloudsLeft.png'), url('./assets/Png/GraphicGround.png'), url('./assets/Png/GraphicTrees.png'), url('./assets/Png/GraphicSky.png'), url('./assets/Png/GraphicBackgroundBlue.png');
    background-position: center center, top right, top left, bottom center, bottom left, bottom center, bottom center;
    background-size: 75%, 75%, 75%, auto, auto, auto, auto;
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.rune_bender_character {
    max-width: 400px;
    position: absolute;
    bottom: 0;
    left: -25px;
    overflow: hidden;
}

.rune_bender_character > .character-image > img {
    max-width: 300px;
    position: relative;
    margin-left: -30px;
}

.lightnings-animate {
    z-index: 3;
    position: relative;
    bottom: 0;
}

/*.lightnings-small-animate > svg,
.lightnings-small-two-animate > svg,
.lightnings-big-animate > svg,
.lightnings-big-two-animate > svg {
    position: absolute;
}*/

.lightnings-small-animate {
    position: absolute;
    width: 100%;
    transform: rotate(4deg);
    margin-left: 40px;
    margin-top: 90px;
}

.lightnings-small-animate > svg:first-child {
    width: 175px !important;
    margin-left: 100px;
    margin-top: -50px;
}

.lightnings-small-animate > svg:last-child {
    width: 175px !important;
    margin-left: 100px;
    margin-top: -50px;
}

.lightnings-small-two-animate {
    position: absolute;
    width: 100%;
    transform: rotate(4deg);
    margin-left: 40px;
    margin-top: 110px;
}

.lightnings-small-two-animate > svg:first-child {
    width: 135px !important;
    margin-left: 135px;
    margin-top: -35px;
}

.lightnings-small-two-animate > svg:last-child {
    width: 135px !important;
    margin-left: 135px;
    margin-top: -35px;
}

.lightnings-big-animate {
    position: absolute;
    width: 390px;
    transform: rotate(4deg);
    margin-left: -15px;
    margin-top: 45px;
}

.lightnings-big-animate > svg:first-child {
    /*margin-top: -135px;*/
    width: 175px !important;
    margin-left: -5px;
    /*margin-bottom: -150px;*/
}

.lightnings-big-animate > svg:last-child {
    /*margin-top: -135px;*/
    width: 175px !important;
    margin-left: -5px;
    /*margin-bottom: -150px;*/
}

.lightnings-big-two-animate {
    position: absolute;
    width: 390px;
    transform: rotate(4deg);
    margin-left: -15px;
    margin-top: 45px;
}

.lightnings-big-two-animate > svg:first-child {
    margin-top: 40px !important;
    width: 115px !important;
    margin-left: 50px;
    /*margin-bottom: -150px;*/
}

.lightnings-big-two-animate > svg:last-child {
    margin-top: 40px !important;
    width: 115px !important;
    margin-left: 50px;
    /*margin-bottom: -150px;*/
}

.character-image {
    z-index: 2;
    position: relative;
    bottom: 0;
}

.sparkles-animate {
    z-index: 1;
    position: absolute;
    bottom: -60px;
    width: 400px;
    height: 400px;
    left: -70px;
    overflow: hidden;
}

.teaser-description {
    min-height: calc(100vh);
    background-image: url('./assets/Png/GraphicBackgroundClouds.png');
    background-position: bottom center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-top: -2px;
}

.teaser-description-character-cone {
    min-height: calc(175vh);
    background-image: url('./assets/Png/GraphicLeshyCone.png');
    /*background-position: calc(100% - 540px) 15%, calc(100% + 212px) 50%;*/
    background-position: calc(100% - 540px) 60%;
    background-size: auto;
    background-repeat: no-repeat;
    width: 100%;
    position: absolute;
    overflow: hidden;
    z-index: 1;
}

.teaser-description-character {
    min-height: calc(250vh);
    background-image: url('./assets/Png/GraphicLeshy.png');
    /*background-position: calc(100% - 540px) 15%, calc(100% + 212px) 50%;*/
    background-position: calc(100% + 212px) 10%;
    background-size: auto;
    background-repeat: no-repeat;
    width: 100%;
    position: absolute;
    overflow: hidden;
    z-index: 1;
}

.teaser-platform {
    min-height: calc(100vh);
    background-image: url('./assets/Png/GraphicBackgroundBlue.png');
    background-position: bottom center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.teaser-trailer {
    min-height: calc(100vh);
    background-image: url('./assets/Png/GraphicBackgroundClouds.png');
    background-position: bottom center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.teaser-screenshots {
    min-height: calc(100vh);
    background-image: url('./assets/Png/GraphicBackgroundBlue.png');
    background-position: bottom center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.corner-stone-rune {
    /*background-image: url('./assets/Png/GraphicStoneCircle.png');
    background-position: top left;*/
    margin: -360px 0px 0px -325px;
    position: absolute;
    transform: rotate(95deg);
}

.corner-stone-rune-trailer {
    /*background-image: url('./assets/Png/GraphicStoneCircle.png');
    background-position: top left;*/
    margin: -360px 0px 0px -325px;
    position: absolute;
    transform: rotate(182deg);
}

.corner-stone-rune-screenshots {
    /*background-image: url('./assets/Png/GraphicStoneCircle.png');
    background-position: top left;*/
    margin: -360px 0px 0px -325px;
    position: absolute;
    transform: rotate(182deg);
}

.fixed-top {
    top: 25px !important;
    right: 25px !important;
}

.frame-game > img {
    width: calc(99vw);
    height: calc(98vh);
    margin: 10px;
    max-height: calc(97vh);
}

.frame-game-normal > img {
    width: calc(99vw);
    height: calc(98vh);
    margin: 10px;
    max-height: calc(97vh);
    position: fixed;
    z-index: 1000;
}

#description {
    z-index: 1000;
    position: relative;
}

#description .frame-game > img {
    width: calc(99vw);
    height: calc(98vh);
    margin: 10px;
    max-height: calc(98vh);
}

.terms-of-service-page .frame-game > img {
    width: calc(99vw);
    height: calc(98vh);
    margin: 10px;
    min-height: calc(100vh);
}

#screenshots .frame-game > img {
    width: calc(99vw);
    height: calc(100vh);
    margin: 10px;
    max-height: calc(97vh);
}

.content-platform,
.content-description {
    position: absolute;
}

.content-description {
    top: 0;
}

.content-description > img:first-child {
    top: 0;
}

.content-platform > img,
.content-description > img {
    margin: 25px 10px 10px 10px;
}

/*.frame-game > div {
    width: calc(99vw);
    height: calc(98vh);
    margin: 100px;
    max-height: calc(97vh);
}*/

.bmwi-logo,
.nurogames-logo {
    width: 200px;
}

.screenshots {
    margin-top: 125px;
    width: calc(100vw);
    min-height: calc(86vh);
    position: relative;
    z-index: 1000;
}

.screenshots > .container > .row:nth-child(2) {
    margin: 30px auto 0px auto;
}

.screenshots > .container > .row:nth-child(2) > div {
    width: 915px;
    margin: auto;
}

.screenshots > .container > .row:nth-child(2) > div:first-child {
    margin: 0px auto 15px auto;
}

.screenshots > .container > .row:nth-child(2) > .big-slider > div > div > div img {
    object-fit: cover;
    width: 700px;
}

.screenshots > .container > .row:nth-child(2) > div:last-child img {
    object-fit: cover;
    width: 183px;
    padding-right: 10px;
}

.trailer {
    margin-top: 125px;
    width: calc(100vw);
    min-height: calc(86vh);
}

.trailer > .container > .row:nth-child(2) {
    margin-top: 25px;
}

.dynamic-video {
    width: 640px;
    height: 480px;
}

.description {
    margin-top: 125px;
    width: calc(100vw);
    min-height: calc(86vh);
}

/*#description {
    background-image: url('./assets/Png/GraphicLeshyCone.png'), url('./assets/Png/GraphicLeshy.png');
    background-position: calc(100% - 540px) 7%, calc(100% + 212px) 50%;
    background-repeat: no-repeat, no-repeat;
    background-size: auto, auto;
}*/

.description > .container > .row {
    margin-top: 25px;
    margin-bottom: 20px;
}

.platforms {
    margin-top: 125px;
    width: calc(100vw);
    min-height: calc(86vh);
    position: relative;
    z-index: 2;
}

.platforms > .container > .row:nth-child(2) {
    margin-top: 25px;
}
.platform-element {
    background-image: url('./assets/Png/GraphicLogoContentBackground.png');
    background-repeat: no-repeat;
    border-radius: 10px;
    padding: 20px;
    border: 2px solid #0F1A25;
    background-color: #0F1A25;
    position: relative;
    cursor: pointer;
}

.platform-element img,
.platform-element a,
.platform-element > a img {
    width: 300px;
    /*padding: 25px;*/
    color: #fff;
    text-decoration: none;
}

.platform-element > a > div {
    width: 100%;
    height: 200px;
    cursor: pointer;
}

.platform-element > a,
.platform-element > a > div {
    color: #fff;
    text-decoration: none;
}

.platform-element div {
    line-height: 4;
}

.platform-element:nth-child(2) div {
    margin-top: 4px;
}

.platform-element:nth-child(5) div {
    margin-top: 20px;
}

.platform-element:nth-child(6) div {
    margin-top: 7px;
}

.platform-element:nth-child(2) > img {
    width: 350px;
    padding: 25px;
    margin-top: 10px;
}

.platform-element:nth-child(6) > img {
    width: 350px;
    padding: 25px;
    margin-top: 0px;
}

.platform-element:nth-child(8) > img {
    width: 100px;
}

.platform-element-hide {
    opacity: 0;
}

.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: #ffffff !important;
}

.slick-dots li button:before {
    color: rgba(255, 255, 255, 0.25) !important;
    font-size: 20px;
    line-height: 20px;
}

/*.slick-dots li,
.slick-dots li button,
.slick-dots li button:before {
    width: 25px !important;
    height: 25px !important;
}*/

.nav-link > img {
    margin-right: 10px;
}

.navbar-nav > ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.is-current > a {
    color: #fff !important;
}

.navigation-mobile-home {
    margin-left: 20px;
    margin-top: 8px !important;
    text-decoration: none !important;
}

.requirements-detail {
    line-height: 2;
}

.requirements-detail > div > div div {
    text-transform: uppercase;
    font-weight: bold;
}

.requirements-detail > div > div div.normal-text {
    text-transform: capitalize;
    font-weight: bold;
}

.requirements-detail span {
    font-weight: bold;
}

.legal-text {
    font-size: 14px;
}

.modal.show {
    z-index: 1000000 !important;
}

.modal {
    color: #000 !important;
}

.modal-body > div {
    margin-bottom: 10px;
}

#registration-betatest input {
    width: 100%;
    border-radius: 5px;
    border: 2px solid #000;
    padding: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
}

#registration-betatest label,
#registration-betatest input {
    margin-bottom: 10px;
}

#registration-betatest label {
    font-weight: bold;
}

.button--sign > button,
.button--sign > button:active,
.button--sign > button:focus,
.button--sign > button:link,
.button--sign > button:visited,
.button--sign > button:hover {
    width: 100%;
    margin-top: 10px;
    background-color: #1E87AC;
    border-color: #1E87AC;
    cursor: pointer;
}

.error-text {
    color: #f00;
    margin-bottom: 10px;
}

.register-success {
    text-align: center;
    font-weight: bold;
}

.additional-image-update {
    height: 100px;
    object-fit: fill !important;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Roboto'), url("./assets/Fonts/Roboto-Regular.ttf") format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Roboto'), url("./assets/Fonts/Roboto-Bold.ttf") format('truetype');
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
    .additional-image-update {
        height: 95px;
        object-fit: fill !important;
    }

    .screenshots > .container > .row:nth-child(2) > .big-slider > div > div > div img {
        object-fit: cover;
        width: 937.5px;
    }

    .screenshots > .container > .row:nth-child(2) > div {
        width: 1015px;
        margin: auto;
    }

    .screenshots > .container > .row:nth-child(2) > div:last-child img {
        object-fit: cover;
        width: 153px;
        padding-right: 10px;
    }
}

@media screen and (min-width: 1401px) {
    .additional-image-update {
        height: 155px;
        object-fit: fill !important;
    }
    .screenshots > .container > .row:nth-child(2) > .big-slider > div > div > div img {
        object-fit: cover;
        width: 1188.5px;
    }

    .screenshots > .container > .row:nth-child(2) > div {
        width: 1215px;
        margin: auto;
    }

    .screenshots > .container > .row:nth-child(2) > div:last-child img {
        object-fit: cover;
        width: 243px;
        padding-right: 10px;
    }
}

@media screen and (min-width: 1201px){
    .platform-element div {
        line-height: 4;
    }

    .platform-element:nth-child(1) div {
        margin-top: 8px;
    }

    .platform-element:nth-child(2) div {
        margin-top: 0px;
    }

    .platform-element:nth-child(3) div {
        margin-top: 8px;
    }

    .platform-element:nth-child(4) div {
        margin-top: 6px;
    }

    .platform-element:nth-child(5) div {
        margin-top: 25px;
    }

    .platform-element:nth-child(6) div {
        margin-top: 0px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px){
    .platform-element div {
        line-height: 2;
    }

    .platform-element:nth-child(2) div {
        margin-top: 0px;
    }

    .platform-element:nth-child(3) div,
    .platform-element:nth-child(4) div {
        margin-top: 5px;
    }

    .platform-element:nth-child(5) div {
        margin-top: 23px;
    }

    .platform-element:nth-child(6) div {
        margin-top: 0px;
    }
}

@media screen and (min-width: 992px) and (min-height: 701px) and (max-height: 725px) and (orientation: landscape){
    #description .frame-game > img {
        width: calc(99vw);
        height: calc(123vh);
        margin: 10px;
        max-height: calc(123vh);
    }
}

@media screen and (min-width: 992px) and (min-height: 726px) and (max-height: 740px) and (orientation: landscape){
    #description .frame-game > img {
        width: calc(99vw);
        height: calc(118vh);
        margin: 10px;
        max-height: calc(118vh);
    }
}

@media screen and (min-width: 992px) and (min-height: 741px) and (max-height: 760px) and (orientation: landscape){
    #description .frame-game > img {
        width: calc(99vw);
        height: calc(116vh);
        margin: 10px;
        max-height: calc(116vh);
    }
}

@media screen and (min-width: 992px) and (min-height: 761px) and (max-height: 780px) and (orientation: landscape){
    #description .frame-game > img {
        width: calc(99vw);
        height: calc(113vh);
        margin: 10px;
        max-height: calc(113vh);
    }
}

@media screen and (min-width: 992px) and (min-height: 781px) and (max-height: 800px) and (orientation: landscape){
    #description .frame-game > img {
        width: calc(99vw);
        height: calc(110vh);
        margin: 10px;
        max-height: calc(110vh);
    }
}

@media screen and (min-width: 992px) and (min-height: 801px) and (max-height: 830px) and (orientation: landscape){
    #description .frame-game > img {
        width: calc(99vw);
        height: calc(108vh);
        margin: 10px;
        max-height: calc(108vh);
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px) and (min-height: 831px) and (max-height: 900px) and (orientation: landscape){
    #description .frame-game > img {
        width: calc(99vw);
        height: calc(127vh);
        margin: 10px;
        max-height: calc(127vh);
    }
}

@media screen and (min-width: 1201px) and (max-width: 1400px) and (min-height: 831px) and (max-height: 900px) and (orientation: landscape){
    #description .frame-game > img {
        width: calc(99vw);
        height: calc(120vh);
        margin: 10px;
        max-height: calc(120vh);
    }
}

@media screen and (min-width: 1401px) and (min-height: 831px) and (max-height: 900px) and (orientation: landscape){
    #description .frame-game > img {
        width: calc(99vw);
        height: calc(114vh);
        margin: 10px;
        max-height: calc(114vh);
    }
}

@media screen and (min-height: 901px) and (max-width: 1440px) and (orientation: landscape) {
    .teaser-image-eleven {
        background-position: left center;
        top: 335px;
        z-index: 20;
        left: 60px;
        transform: rotate(5deg);
    }

    .teaser-image-twelve {
        background-position: left center;
        top: 315px;
        z-index: 20;
        left: 35px;
        transform: rotate(5deg);
    }
}

@media screen and (min-height: 851px) and (max-height: 900px) and (max-width: 1440px) and (orientation: landscape) {
    .teaser-image-eleven {
        background-position: left center;
        top: 315px;
        z-index: 20;
        left: 60px;
        transform: rotate(5deg);
    }

    .teaser-image-twelve {
        background-position: left center;
        top: 295px;
        z-index: 20;
        left: 35px;
        transform: rotate(5deg);
    }
}

@media screen and (min-height: 801px) and (max-height: 850px) and (max-width: 1440px) and (orientation: landscape) {
    .teaser-image-eleven {
        background-position: left center;
        top: 295px;
        z-index: 20;
        left: 60px;
        transform: rotate(5deg);
    }

    .teaser-image-twelve {
        background-position: left center;
        top: 275px;
        z-index: 20;
        left: 35px;
        transform: rotate(5deg);
    }
}

@media screen and (min-height: 751px) and (max-height: 800px) and (max-width: 1440px) and (orientation: landscape) {
    .teaser-image-eleven {
        background-position: left center;
        top: 275px;
        z-index: 20;
        left: 60px;
        transform: rotate(5deg);
    }

    .teaser-image-twelve {
        background-position: left center;
        top: 255px;
        z-index: 20;
        left: 35px;
        transform: rotate(5deg);
    }
}

@media screen and (min-height: 701px) and (max-height: 750px) and (max-width: 1440px) and (orientation: landscape) {
    .teaser-image-eleven {
        background-position: left center;
        top: 280px;
        z-index: 20;
        left: 20px;
        transform: rotate(5deg);
    }

    .teaser-image-twelve {
        background-position: left center;
        top: 240px;
        z-index: 20;
        left: -80px;
        transform: rotate(5deg);
    }
}

@media screen and (min-height: 651px) and (max-height: 700px) and (max-width: 1440px) and (orientation: landscape) {
    .teaser-image-eleven {
        background-position: left center;
        top: 225px;
        z-index: 20;
        left: 20px;
        transform: rotate(5deg);
    }

    .teaser-image-twelve {
        background-position: left center;
        top: 190px;
        z-index: 20;
        left: -80px;
        transform: rotate(5deg);
    }
}

@media screen and (min-height: 601px) and (max-height: 650px) and (max-width: 1440px) and (orientation: landscape) {
    .teaser-image-eleven {
        background-position: left center;
        top: 185px;
        z-index: 20;
        left: 20px;
        transform: rotate(5deg);
    }

    .teaser-image-twelve {
        background-position: left center;
        top: 150px;
        z-index: 20;
        left: -80px;
        transform: rotate(5deg);
    }
}

@media screen and (min-height: 551px) and (max-height: 600px) and (max-width: 1440px) and (orientation: landscape) {
    .teaser-image-eleven {
        background-position: left center;
        top: 145px;
        z-index: 20;
        left: 20px;
        transform: rotate(5deg);
    }

    .teaser-image-twelve {
        background-position: left center;
        top: 115px;
        z-index: 20;
        left: -80px;
        transform: rotate(5deg);
    }
}

@media screen and (min-height: 501px) and (max-height: 550px) and (max-width: 1440px) and (orientation: landscape) {
    .teaser-image-eleven {
        background-position: left center;
        top: 105px;
        z-index: 20;
        left: 20px;
        transform: rotate(5deg);
    }

    .teaser-image-twelve {
        background-position: left center;
        top: 75px;
        z-index: 20;
        left: -80px;
        transform: rotate(5deg);
    }
}

@media screen and (min-height: 451px) and (max-height: 500px) and (max-width: 1440px) and (orientation: landscape) {
    .teaser-image-eleven {
        background-position: left center;
        top: 55px;
        z-index: 20;
        left: 20px;
        transform: rotate(5deg);
    }

    .teaser-image-twelve {
        background-position: left center;
        top: 25px;
        z-index: 20;
        left: -80px;
        transform: rotate(5deg);
    }
}

@media screen and (max-height: 450px) and (max-width: 1440px) and (orientation: landscape) {
    .teaser-image-eleven {
        background-position: left center;
        top: 5px;
        z-index: 20;
        left: 20px;
        transform: rotate(5deg);
    }

    .teaser-image-twelve {
        background-position: left center;
        top: -30px;
        z-index: 20;
        left: -80px;
        transform: rotate(5deg);
    }
}

/* Big screens */

@media screen and (min-height: 901px) and (min-width: 1441px) and (orientation: landscape) {
    .teaser-image-eleven {
        background-position: left center;
        top: 515px;
        z-index: 20;
        left: 20px;
        transform: rotate(5deg);
    }

    .teaser-image-twelve {
        background-position: left center;
        top: 485px;
        z-index: 20;
        left: -80px;
        transform: rotate(5deg);
    }
}

@media screen and (min-height: 851px) and (max-height: 900px) and (min-width: 1441px) and (orientation: landscape) {
    .teaser-image-eleven {
        background-position: left center;
        top: 460px;
        z-index: 20;
        left: 20px;
        transform: rotate(5deg);
    }

    .teaser-image-twelve {
        background-position: left center;
        top: 430px;
        z-index: 20;
        left: -80px;
        transform: rotate(5deg);
    }
}

@media screen and (min-height: 801px) and (max-height: 850px) and (min-width: 1441px) and (orientation: landscape) {
    .teaser-image-eleven {
        background-position: left center;
        top: 420px;
        z-index: 20;
        left: 20px;
        transform: rotate(5deg);
    }

    .teaser-image-twelve {
        background-position: left center;
        top: 390px;
        z-index: 20;
        left: -80px;
        transform: rotate(5deg);
    }
}

@media screen and (min-height: 751px) and (max-height: 800px) and (min-width: 1441px) and (orientation: landscape) {
    .teaser-image-eleven {
        background-position: left center;
        top: 370px;
        z-index: 20;
        left: 20px;
        transform: rotate(5deg);
    }

    .teaser-image-twelve {
        background-position: left center;
        top: 340px;
        z-index: 20;
        left: -80px;
        transform: rotate(5deg);
    }
}

@media screen and (min-height: 701px) and (max-height: 750px) and (min-width: 1441px) and (orientation: landscape) {
    .teaser-image-eleven {
        background-position: left center;
        top: 320px;
        z-index: 20;
        left: 20px;
        transform: rotate(5deg);
    }

    .teaser-image-twelve {
        background-position: left center;
        top: 290px;
        z-index: 20;
        left: -80px;
        transform: rotate(5deg);
    }
}

@media screen and (min-height: 651px) and (max-height: 700px) and (min-width: 1441px) and (orientation: landscape) {
    .teaser-image-eleven {
        background-position: left center;
        top: 270px;
        z-index: 20;
        left: 20px;
        transform: rotate(5deg);
    }

    .teaser-image-twelve {
        background-position: left center;
        top: 240px;
        z-index: 20;
        left: -80px;
        transform: rotate(5deg);
    }
}

@media screen and (min-height: 601px) and (max-height: 650px) and (min-width: 1441px) and (orientation: landscape) {
    .teaser-image-eleven {
        background-position: left center;
        top: 210px;
        z-index: 20;
        left: 20px;
        transform: rotate(5deg);
    }

    .teaser-image-twelve {
        background-position: left center;
        top: 180px;
        z-index: 20;
        left: -80px;
        transform: rotate(5deg);
    }
}

@media screen and (min-height: 551px) and (max-height: 600px) and (min-width: 1441px) and (orientation: landscape) {
    .teaser-image-eleven {
        background-position: left center;
        top: 160px;
        z-index: 20;
        left: 20px;
        transform: rotate(5deg);
    }

    .teaser-image-twelve {
        background-position: left center;
        top: 130px;
        z-index: 20;
        left: -80px;
        transform: rotate(5deg);
    }
}

@media screen and (min-height: 501px) and (max-height: 550px) and (min-width: 1441px) and (orientation: landscape) {
    .teaser-image-eleven {
        background-position: left center;
        top: 90px;
        z-index: 20;
        left: 20px;
        transform: rotate(5deg);
    }

    .teaser-image-twelve {
        background-position: left center;
        top: 60px;
        z-index: 20;
        left: -80px;
        transform: rotate(5deg);
    }
}

@media screen and (max-height: 500px) and (min-width: 1441px) and (orientation: landscape) {
    .teaser-image-eleven {
        background-position: left center;
        top: 60px;
        z-index: 20;
        left: 20px;
        transform: rotate(5deg);
    }

    .teaser-image-twelve {
        background-position: left center;
        top: 30px;
        z-index: 20;
        left: -80px;
        transform: rotate(5deg);
    }
}

@media screen and (min-height: 1201px) {
    .teaser-image-one {
        background-size: 100% 100%;
    }

    .teaser-image-two {
        background-size: 100% 100%;
    }
}

@media screen and (min-width: 1201px) {
    .teaser-image-one {
        background-size: 100% 100%;
    }

    .teaser-image-two {
        background-size: 100% 100%;
    }
}

/*@media screen and (min-height: 701px) and (max-height: 900px) and (orientation: landscape) {
    .screenshots > .container > .row:nth-child(2) {
        margin: 50px auto 0px auto;
        width: 43%;
    }
}

@media screen and (min-height: 701px) and (max-height: 900px) and (orientation: portrait) {
    .screenshots > .container > .row:nth-child(2) {
        margin: 50px auto 0px auto;
        width: 43%;
    }
}*/

@media screen and (max-width: 767px) and (min-height: 701px) and (max-height: 900px) and (orientation: landscape) {
    .screenshots > .container > .row:nth-child(2) {
        margin: 50px auto 0px auto;
        width: 80% !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) and (min-height: 701px) and (max-height: 900px) and (orientation: landscape) {
    .screenshots > .container > .row:nth-child(2) {
        margin: 30px auto 0px auto;
        width: 65% !important;
    }
}

@media screen and (min-width: 992px) and (max-width: 1100px) {
    .screenshots > .container > .row:nth-child(2) {
        margin: 30px auto 0px auto;
        width: 80% !important;
    }
    .screenshots > .container > .row:nth-child(2) > div:last-child img {
        object-fit: cover;
        width: 133px;
        padding-right: 10px;
    }
    .additional-image-update {
        height: 82px;
        object-fit: fill !important;
    }
}

@media screen and (max-height: 700px) and (orientation: landscape) {
    #screenshots > div.screenshots > div > div:nth-child(2) > div:nth-child(1) {
        display: none;
    }
}

@media screen and (min-width: 992px){
    .navbar-nav > ul > li {
        display: inline-block;
    }
    .vertical-slider-menu {
        /*background-image: url('./assets/svg/verticalMenu/borderMenu.svg');
        background-position: center right;
        background-repeat: no-repeat;
        background-size: auto;*/
        position: fixed;
        right: 10px;
        height: 410px;
        width: 70px;
        margin: auto;
        top: 25%;
        z-index: 10000;
    }

    .main-border {
        position: relative;
    }

    .main-border-elements {
        position: absolute;
        top: 0;
        right: 25px;
        margin: auto;
        z-index: 10000;
    }

    .element-one,
    .element-two,
    .element-three,
    .element-four,
    .element-five {
        margin: 45px auto;
    }
}

@media screen and (min-width: 992px) and (max-width: 1100px){
    .teaser-image-nine {
        background-size: 500px;
        /*left: 450px;*/
    }

    .platform-element:nth-child(2) > img {
        width: 250px;
        padding: 25px;
        margin-top: 10px;
    }

    .platform-element:nth-child(6) > img {
        width: 250px;
        padding: 25px;
        margin-top: 0px;
    }
}

@media screen and (min-width: 1101px) and (max-width: 1200px){
    .additional-image-update {
        height: 115.33px;
        object-fit: fill !important;
    }

    .screenshots > .container > .row:nth-child(2) > .big-slider > div > div > div img {
        object-fit: cover;
        width: 905px;
    }

    .screenshots > .container > .row:nth-child(2) > div {
        width: 1215px;
        margin: auto;
    }
    .teaser-image-nine {
        background-size: 600px;
        /*left: 450px;*/
    }

    .platform-element:nth-child(2) > img {
        width: 250px;
        padding: 25px;
        margin-top: 10px;
    }

    .platform-element:nth-child(6) > img {
        width: 250px;
        padding: 25px;
        margin-top: 0px;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1300px){
    .teaser-image-nine {
        background-size: 700px;
        /*left: 450px;*/
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    .teaser-description-character-cone {
         min-height: calc(175vh);
         background-image: url('./assets/Png/GraphicLeshyCone.png');
         /*background-position: calc(100% - 540px) 15%, calc(100% + 212px) 50%;*/
         background-position: calc(100% - 200px) 58%;
         background-size: auto;
         background-repeat: no-repeat;
         width: 100%;
         position: absolute;
         overflow: hidden;
         z-index: 1;
     }

    .teaser-description-character {
        min-height: calc(250vh);
        background-image: url('./assets/Png/GraphicLeshy.png');
        /*background-position: calc(100% - 540px) 15%, calc(100% + 212px) 50%;*/
        background-position: calc(100% + 542px) 20%;
        background-size: auto;
        background-repeat: no-repeat;
        width: 100%;
        position: absolute;
        overflow: hidden;
        z-index: 1;
    }
}

@media screen and (min-width: 992px) and (max-width: 1400px) {
    .button-trailer {
        z-index: 10000;
        position: absolute;
        top: 44%;
        width: 200px;
        height: 100px;
        /* left: auto; */
        right: 250px;
    }
    .button-wishlist {
        z-index: 10000;
        position: absolute;
        top: 65%;
        width: 200px;
        height: 100px;
        /* left: auto; */
        right: 250px;
        margin-top: 90px;
    }
    .button-betatest {
        z-index: 10000;
        position: absolute;
        top: 84%;
        width: 200px;
        height: 100px;
        /* left: auto; */
        right: 250px;
        margin-top: 180px;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1700px) {
    .button-container-main {
        max-width: 350px;
        position: relative;
        top: 44%;
        left: 40%;
        margin: auto;
    }
    .button-betatest {
        z-index: 10000;
        position: absolute;
        top: 43%;
        width: 200px;
        height: 100px;
        /* left: auto; */
        right: 733px;
        margin-top: 90px;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .button-trailer {
        z-index: 10000;
        position: absolute;
        top: 44%;
        width: 200px;
        height: 100px;
        /* left: auto; */
        right: 500px;
    }
    .button-wishlist {
        z-index: 10000;
        position: absolute;
        top: 65%;
        width: 200px;
        height: 100px;
        /* left: auto; */
        right: 500px;
        margin-top: 90px;
    }
    .button-betatest {
        z-index: 10000;
        position: absolute;
        top: 84%;
        width: 200px;
        height: 100px;
        /* left: auto; */
        right: 500px;
        margin-top: 180px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px){
    .teaser-image-nine {
        background-size: 700px;
        /*left: 450px;*/
    }

    .platform-element:nth-child(7) > img {
        width: 100px;
        padding: 5px 10px;
    }

    .dynamic-video {
        width: 600px;
        height: 480px;
    }

    .platform-element {
        margin-bottom: 15px !important;
        background-size: 100%;
    }
    .teaser-description {
        min-height: calc(100vh);
        background-image: url('./assets/Png/GraphicLeshyConeFlip.png'), url('./assets/Png/GraphicLeshyFlip.png'), url('./assets/Png/GraphicBackgroundClouds.png');
        background-position: calc(100% - 650px) 73%, calc(100% - 500px) 100%, bottom center;
        background-size: 50px, 400px, 100% 100%;
        background-repeat: no-repeat, no-repeat, no-repeat;
        width: 100%;
        position: relative;
        overflow: hidden;
    }

    .button-trailer {
        z-index: 10000;
        position: absolute;
        top: 60%;
        width: 100px;
        height: 100px;
        /* left: auto; */
        right: 200px;
    }

    .button-wishlist {
        z-index: 10000;
        position: absolute;
        top: 65%;
        width: 100px;
        height: 100px;
        /* left: auto; */
        right: 200px;
    }

    .button-betatest {
        z-index: 10000;
        position: absolute;
        top: 59.5%;
        width: 100px;
        height: 100px;
        /* left: auto; */
        right: 200px;
    }
    .button-wishlist > a > img,
    .button-betatest > a > img,
    .button-betatest > img,
    .button-trailer > img {
        width: 150px;
    }

    .button-wishlist:hover span,
    .button-betatest:hover span,
    .button-trailer:hover span {
        color: #FFFFFF;
    }

    .button-wishlist span,
    .button-betatest span,
    .button-trailer span {
        text-transform: uppercase;
        text-decoration: none;
        position: absolute;
        top: 10px;
        left: 40px;
        font-size: 16px;
        color: #A7CFDE;
    }
    .button-betatest span {
        text-transform: uppercase;
        text-decoration: none;
        position: absolute;
        top: 10px;
        left: 52.5px !important;
        font-size: 16px;
        color: #A7CFDE;
    }

    .button-wishlist span.german-text,
    .button-betatest span.german-text,
    .button-trailer span.german-text {
        left: 45px;
        font-size: 14px;
    }

    .button-trailer span.german-text {
        left: 48px !important;
        font-size: 14px;
    }

    .button-wishlist span.german-text-two,
    .button-betatest span.german-text-two,
    .button-trailer span.german-text-two {
        left: 28px;
        font-size: 14px;
    }

    .button-wishlist span.german-text-two {
        left: 38px;
    }

    .button-wishlist span.other-text,
    .button-betatest span.other-text,
    .button-trailer span.other-text {
        left: 10px;
        width: 200px;
        font-size: 14px;
    }

    .button-wishlist span.rest-text-one,
    .button-betatest span.rest-text-one,
    .button-trailer span.rest-text-one {
        left: 10px;
        width: 200px;
        font-size: 14px;
    }

    .button-wishlist span.rest-text-two,
    .button-betatest span.rest-text-two,
    .button-trailer span.rest-text-two {
        left: 10px;
        width: 200px;
        font-size: 14px;
    }

    .button-wishlist span.rest-text,
    .button-betatest span.rest-text,
    .button-trailer span.rest-text {
        left: 10px;
        width: 200px;
        font-size: 14px;
    }

    .button-wishlist span.rest-text {
        left: 42px;
        width: 200px;
        font-size: 14px;
    }

    .button-wishlist span.rest-text-other,
    .button-betatest span.rest-text-other,
    .button-trailer span.rest-text-other {
        left: 45px;
        width: 200px;
        font-size: 14px;
    }

    .button-wishlist span.rest-text-other-two,
    .button-betatest span.rest-text-other-two,
    .button-trailer span.rest-text-other-two {
        left: 40px;
        width: 200px;
        font-size: 14px;
    }

    .button-wishlist span.rest-text-other-two {
        left: 52px !important;
    }

    .button-wishlist span.rest-text-other-three,
    .button-betatest span.rest-text-other-three,
    .button-trailer span.rest-text-other-three {
        left: 20px;
        width: 200px;
        font-size: 14px;
    }

    .button-wishlist span.rest-text-other-three {
        left: 35px;
        width: 200px;
        font-size: 14px;
    }

    .button-wishlist span.rest-text-other-four,
    .button-betatest span.rest-text-other-four,
    .button-trailer span.rest-text-other-four {
        left: 37px;
        width: 200px;
        font-size: 14px;
    }

    .button-wishlist span.rest-text-other-five,
    .button-betatest span.rest-text-other-five,
    .button-trailer span.rest-text-other-five {
        left: 45px;
        width: 200px;
        font-size: 14px;
    }

    .button-wishlist span.rest-text-other-six,
    .button-betatest span.rest-text-other-six,
    .button-trailer span.rest-text-other-six {
        left: 15px;
        width: 200px;
        font-size: 14px;
    }
}

@media screen and (max-width: 991px){
    .button-wishlist:hover > a > img,
    .button-betatest:hover > a > img,
    .button-trailer:hover > a > img {
        fill: #53C2EC;
        transition: transform .5s ease-in-out;
        transform: scale(1.15, 1.15);
    }

    .teaser-platform {
        background-size: 100% 100% !important;
    }

    .platform-element > img {
        width: 300px;
        /*padding: 25px;*/
    }

    .platform-element:nth-child(2) > img {
        width: 330px;
        padding: 25px;
        margin-top: 10px;
    }

    .platform-element:nth-child(6) > img {
        width: 330px;
        padding: 25px;
        margin-top: 0px;
    }

    .platform-element:nth-child(8) > img {
        width: 100px;
    }

    .navbar-extra {
        background-color: rgba(0, 0, 0, 0.5);
        min-width: calc(100vw);
        position: relative;
        overflow: hidden;
    }

    .navbar-collapse.collapse.show {
        min-height: calc(100vh);
    }

    .fixed-top {
        top: 0 !important;
    }
    .navigation-mobile {
        top: 8px !important;
    }
    .screenshots > .container > .row:nth-child(2) > div {
        margin: 50px auto 0px auto;
        width: 715px;
    }
    .screenshots > .container > .row:nth-child(2) > div:last-child img {
        object-fit: cover;
        width: 100% !important;
        padding-right: 0px !important;
    }
    .screenshots > .container > .row:nth-child(2) > div {
        margin: 50px auto 0px auto;
    }
}

@media screen and (min-width: 481px) and (max-width: 767px){
    .teaser-description {
        min-height: calc(100vh);
        background-image: url('./assets/Png/GraphicLeshyConeFlip.png'), url('./assets/Png/GraphicLeshyFlip.png'), url('./assets/Png/GraphicBackgroundClouds.png');
        background-position: calc(100% - 400px) 75%, calc(100% - 250px) 100%, bottom center;
        background-size: 50px, 400px, 100% 100%;
        background-repeat: no-repeat, no-repeat, no-repeat;
        width: 100%;
        position: relative;
        overflow: hidden;
    }
}

@media screen and (max-width: 480px){
    .teaser-description {
        min-height: calc(100vh);
        background-image: url('./assets/Png/GraphicLeshyConeFlip.png'), url('./assets/Png/GraphicLeshyFlip.png'), url('./assets/Png/GraphicBackgroundClouds.png');
        background-position: calc(100% - 300px) 78%, calc(100% - 150px) 100%, bottom center;
        background-size: 50px, 400px, 100% 100%;
        background-repeat: no-repeat, no-repeat, no-repeat;
        width: 100%;
        position: relative;
        overflow: hidden;
    }
}

@media screen and (min-height: 651px) and (max-height: 740px) and (min-width: 768px) and (max-width: 991px){
    .button-trailer {
        z-index: 10000;
        position: absolute;
        top: 65% !important;
        width: 100px;
        height: 100px;
        /* left: auto; */
        right: 200px;
    }
    .button-wishlist {
        z-index: 10000;
        position: absolute;
        top: 75% !important;
        width: 100px;
        height: 100px;
        /* left: auto; */
        right: 200px;
    }
    .button-betatest {
        z-index: 10000;
        position: absolute;
        top: 85% !important;
        width: 100px;
        height: 100px;
        /* left: auto; */
        right: 200px;
    }
}

@media screen and (max-height: 650px) and (min-width: 768px) and (max-width: 991px){
    .button-trailer {
        z-index: 10000;
        position: absolute;
        top: 69% !important;
        width: 100px;
        height: 100px;
        /* left: auto; */
        right: 150px;
    }
    .button-wishlist {
        z-index: 10000;
        position: absolute;
        top: 79% !important;
        width: 100px;
        height: 100px;
        /* left: auto; */
        right: 150px;
    }
    .button-betatest {
        z-index: 10000;
        position: absolute;
        top: 89% !important;
        width: 100px;
        height: 100px;
        /* left: auto; */
        right: 150px;
    }
}

@media screen and (max-height: 650px) and (max-width: 767px){
    .button-trailer {
        z-index: 10000;
        position: absolute;
        top: 67% !important;
        width: 100px;
        height: 100px;
        /* left: auto; */
        right: 150px;
    }
    .button-wishlist {
        z-index: 10000;
        position: absolute;
        top: 77% !important;
        width: 100px;
        height: 100px;
        /* left: auto; */
        right: 150px;
    }
    .button-betatest {
        z-index: 10000;
        position: absolute;
        top: 87% !important;
        width: 100px;
        height: 100px;
        /* left: auto; */
        right: 150px;
    }
}

@media screen and (min-width: 481px) and (max-width: 767px){
    .platform-element > img {
        margin-left: -50px !important;
    }
}

@media screen and (max-width: 480px){
    .platform-element > img {
        margin-left: -30px !important;
    }
}

@media screen and (max-width: 767px){
    .platforms > .container > .row {
        margin-right: -30px !important;
    }
    .platforms > .container > .row > div > div {
        width: 90% !important;
    }
    .button-trailer span.german-text {
        left: 48px !important;
    }
    .platform-element:nth-child(7) > img {
        width: 150px;
    }
    #footer > div > div:nth-child(1) > div:nth-child(2) {
        margin: 5px auto 15px auto !important;
        height: 200px;
    }

    .button-trailer {
        z-index: 10000;
        position: absolute;
        top: 59%;
        width: 100px;
        height: 100px;
        /* left: auto; */
        right: 150px;
    }

    .button-wishlist {
        z-index: 10000;
        position: absolute;
        top: 66%;
        width: 100px;
        height: 100px;
        /* left: auto; */
        right: 150px;
    }

    .button-betatest {
        z-index: 10000;
        position: absolute;
        top: 62%;
        width: 100px;
        height: 100px;
        /* left: auto; */
        right: 150px;
    }
    .button-wishlist > a > img,
    .button-betatest > a > img,
    .button-betatest span > img,
    .button-betatest > img,
    .button-trailer span > img,
    .button-trailer > img {
        width: 150px;
    }

    .button-wishlist:hover span,
    .button-betatest:hover span,
    .button-trailer:hover span {
        color: #FFFFFF;
    }

    .button-wishlist span,
    .button-betatest span,
    .button-trailer span {
        text-transform: uppercase;
        text-decoration: none;
        position: absolute;
        top: 10px;
        left: 40px;
        font-size: 16px;
        color: #A7CFDE;
    }
    .button-betatest span {
        text-transform: uppercase;
        text-decoration: none;
        position: absolute;
        top: 10px;
        left: 52.5px !important;
        font-size: 16px;
        color: #A7CFDE;
    }

    .button-wishlist span.german-text,
    .button-betatest span.german-text,
    .button-trailer span.german-text {
        left: 20px;
        font-size: 14px;
    }

    .button-wishlist span.german-text-two,
    .button-betatest span.german-text-two,
    .button-trailer span.german-text-two {
        left: 28px;
        font-size: 14px;
    }

    .button-wishlist span.german-text-two {
        left: 40px !important;
        font-size: 14px;
    }

    .button-wishlist span.other-text,
    .button-betatest span.other-text,
    .button-trailer span.other-text {
        left: 15px;
        width: 200px;
        font-size: 14px;
    }

    .button-wishlist span.german-text,
    .button-betatest span.german-text,
    .button-trailer span.german-text {
        left: 45px;
        font-size: 14px;
    }

    .button-wishlist span.rest-text-one,
    .button-betatest span.rest-text-one,
    .button-trailer span.rest-text-one {
        left: 10px;
        width: 200px;
        font-size: 14px;
    }

    .button-wishlist span.rest-text-two,
    .button-betatest span.rest-text-two,
    .button-trailer span.rest-text-two {
        left: 10px;
        width: 200px;
        font-size: 14px;
    }

    .button-wishlist span.rest-text,
    .button-betatest span.rest-text,
    .button-trailer span.rest-text {
        left: 10px;
        width: 200px;
        font-size: 14px;
    }

    .button-wishlist span.rest-text {
        left: 42px !important;
        width: 200px;
        font-size: 14px;
    }

    .button-wishlist span.rest-text-other,
    .button-betatest span.rest-text-other,
    .button-trailer span.rest-text-other {
        left: 45px;
        width: 200px;
        font-size: 14px;
    }

    .button-wishlist span.rest-text-other-two,
    .button-betatest span.rest-text-other-two,
    .button-trailer span.rest-text-other-two {
        left: 40px;
        width: 200px;
        font-size: 14px;
    }

    .button-wishlist span.rest-text-other-two {
        left: 52px !important;
        width: 200px;
        font-size: 14px;
    }

    .button-wishlist span.rest-text-other-three,
    .button-betatest span.rest-text-other-three,
    .button-trailer span.rest-text-other-three {
        left: 20px;
        width: 200px;
        font-size: 14px;
    }

    .button-wishlist span.rest-text-other-three {
        left: 35px;
        width: 200px;
        font-size: 14px;
    }

    .button-wishlist span.rest-text-other-four,
    .button-betatest span.rest-text-other-four,
    .button-trailer span.rest-text-other-four {
        left: 37px;
        width: 200px;
        font-size: 14px;
    }

    .button-wishlist span.rest-text-other-five,
    .button-betatest span.rest-text-other-five,
    .button-trailer span.rest-text-other-five {
        left: 45px;
        width: 200px;
        font-size: 14px;
    }

    .button-wishlist span.rest-text-other-six,
    .button-betatest span.rest-text-other-six,
    .button-trailer span.rest-text-other-six {
        left: 15px;
        width: 200px;
        font-size: 14px;
    }

    .rune_bender_character {
        max-width: 300px;
        position: absolute;
        bottom: 0;
        left: -25px;
        overflow: hidden;
    }
}

@media screen and (min-width: 481px) and (max-width: 767px){
    .platform-element {
        margin-bottom: 15px !important;
        background-size: 90%;
        margin-left: 20px;
    }

    .dynamic-video {
        width: 400px;
        height: 320px;
    }
}

@media screen and (max-width: 480px){
    .platform-element {
        width: 90% !important;
        margin: 7.5px auto;
    }

    .dynamic-video {
        width: 320px;
        height: 240px;
    }
}

@media screen and (max-width: 400px){
    .platform-element > img {
        max-width: 200px;
    }
}

/* Default */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
